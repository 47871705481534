import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Observer, fromEvent, merge} from 'rxjs';
import {map} from 'rxjs/operators';
import {MessagingService} from './shared/services/messaging.service';
import {AutoLogoutService} from './shared/services/auto-logout.service';
import {PopupMessage} from './alerts';
import {AuthService} from './modules/auth/services/auth.service';
import {UserService} from "./modules/auth/services/user.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public products: any[] = [];
  public lang: string = 'ru';

  constructor(
    private authService: AuthService,
    // private autoLogout: AutoLogoutService,
    private translate: TranslateService,
    private messaging: MessagingService,
    private userService: UserService
  ) {
    // if (authService.currentUserValue) {
    //   userService.getMe().then(response => {
    //     if (response) {
    //       let userData = localStorage.getItem('user');
    //       if (userData) {
    //         let userDataJson = JSON.parse(userData);
    //         userDataJson.user = response;
    //         localStorage.setItem('user', JSON.stringify(userDataJson));
    //         authService.currentUserSubject.next(userDataJson);
    //       }
    //     }
    //   });
    // }
  }

  ngOnInit() {
    this.translate.setDefaultLang(this.lang);
    let lang = localStorage.getItem('lang');
    if (lang) {
      this.lang = lang;
      this.translate.use(lang);
      localStorage.setItem('lang', lang);
    }
    this.createOnline$().subscribe((isOnline) => {
      if (!isOnline) {
        this.messaging.publish(new PopupMessage('Интернет соединение прервано!', true));
      } else {
        this.messaging.publish(new PopupMessage('', false));
      }
    });
  }

  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }


}
