<app-admin></app-admin>
<div class="main-info">
  <table class="styled-table">
    <thead>
    <tr>
      <th>№</th>
      <th>{{'form.form_2.org'|translate}}</th>
      <th>{{'form.form_2.fio'|translate}}</th>
      <th>{{'form.form_2.address'|translate}}</th>
      <th>{{'form.form_2.help'|translate}}</th>
      <th>{{'form.contacts'|translate}}</th>
      <th>{{'form.email'|translate}}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let t of data; let i = index">
      <td>{{t.id}}</td>
      <td>
        {{t.org_Name}}
      </td>
      <td>
        {{t.full_Name}}
      </td>
      <td>
        {{t.address}}
      </td>
      <td>
        {{t.sponsorship}}
      </td>
      <td>
        {{t.contact_data}}
      </td>
      <td>
        {{t.email}}
      </td>
    </tr>
    </tbody>
  </table>
  <mat-paginator [length]="20"
                 [pageSize]="20"
                 aria-label="Select page" style="float: left">
  </mat-paginator>
  <button mat-button (click)="export()">Выгрузить</button>
</div>


