import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input() color: 'default' | 'primary' | 'danger' = 'default';
  @Input() type: 'submit' | 'button' = 'button';
  @Input() textAlign: 'left' | 'center' | 'right' = 'center';
  @Input() disabled: boolean;
  @Input() icon: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  getClasses() {
    let classes = ['btn'];
    classes.push(this.color);
    classes.push(this.textAlign);
    if (this.icon) {
      classes.push('icon');
    }
    return classes.join(' ');
  }

}
