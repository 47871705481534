import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {MessagingService} from '../../../shared/services/messaging.service';
import {Router} from '@angular/router';
import {AlertMessage, PopupMessage } from 'src/app/alerts';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private messaging: MessagingService,
    private router: Router
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!window.navigator.onLine) {
      this.messaging.publish(new PopupMessage('Интернет соединение прервано!', true));
      return next.handle(request);
    } else {
      return next.handle(request).pipe(catchError(err => {
        let title = err.status + ' ' + err.statusText;
        let errMessages = [];
        if (err && err.error) {
          let object = err.error;
          let keys = Object.keys(object);
          keys.forEach(k => {
            let obj = '';
            if (Array.isArray(object[k])) {
              console.log('array');
              obj += k + ': ';
              object[k].forEach(o => {
                obj += o;
              });
              errMessages.push(obj);
            } else {
              obj += k + ': ' + object[k];
              errMessages.push(obj);
            }
          });
        }
        this.messaging.publish(new AlertMessage(title, errMessages, true, true));
        return throwError(err);
      }));
    }
  }

}
