import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-entry-template',
  templateUrl: './entry-template.component.html',
  styleUrls: ['./entry-template.component.scss']
})
export class EntryTemplateComponent implements OnInit {

  @Input() icon: string = 'icon-bell';
  @Input() iconColor: string  = '#F5413D';
  @Input() iconBg: string = 'rgba(252, 208, 207, 0.5)';
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() buttonName: string = this.translate.instant('create');

  @Output() action = new EventEmitter<any>();


  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit() {
  }

  create() {
    this.action.emit('create');
  }

}
