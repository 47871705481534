import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import * as d3 from 'd3';

interface Data {
  days: number[];
  values: number[];
  color?: string;
}

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit, OnChanges {

  @Input() svgWidth: number = 480;
  @Input() svgHeight: number = 150;

  @Input() fact: Data = {
    days: [],
    values: [],
    color: 'transparent',
  };

  @Input() options = {
    xAxis: {
      ticks: [],
      count: 4,
      lineShow: true,
      labelShow: true,
    },
    yAxis: {
      ticks: [],
      count: 4,
      lineShow: true,
      labelShow: true
    }
  };

  maxValue: number = -9999999999999999999999;
  minValue: number = 9999999999999999999999;
  absoluteMaxValue: number;
  absoluteMinValue: number;
  lengthX: number = 0;

  xAxisTickLength: number = 0;
  yAxisTickLength: number = 0;

  constructor(private elem: ElementRef) {
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      this.findMinMax();
      this.findAbsoluteMinMax();
      this.lengthX = this.svgWidth / (this.fact.days.length - 1);
      this.calcXAxis();
      this.calcYAxis();
      setTimeout(() => {
        let svg = d3.select(this.elem.nativeElement).select('svg');
        svg.select('.fact-line path')
          .attr('d', this.getPath(this.fact.days, this.fact.values));
        svg.select('.fact-area path')
          .attr('d', this.getPolygon(this.fact.days, this.fact.values));

      }, 10);
    }, 100);
  }

  calcXAxis() {
    this.xAxisTickLength = this.svgWidth / (this.options.xAxis.count);
  }

  calcYAxis() {
    this.yAxisTickLength = this.svgHeight / (this.options.yAxis.count - 1);
    let step = this.maxValue / (this.options.yAxis.count - 1);
    for (let i = 0; i < this.options.yAxis.count; i++) {
      this.options.yAxis.ticks.push((i * step));
    }
    this.options.yAxis.ticks.reverse();
  }

  getRounded(value: any) {
    value = parseInt(value);
    let length = value.toString().length;
    let base = Math.pow(10, length - 1);
    return Math.round(value / base) * base;
  }

  findMinMax() {
    if (this.fact.values.length <= 0) {
      this.maxValue = 0;
      this.minValue = 0;
    }
    if (this.fact.values.length >= 1) {
      this.minValue = 0;
      this.maxValue = Math.max(...this.fact.values);
      this.maxValue = this.maxValue * 1.3;
    }
  }

  findAbsoluteMinMax() {
    if (this.maxValue == this.minValue) {
      this.absoluteMaxValue = this.maxValue;
      this.absoluteMinValue = 0;
    }
    if (this.maxValue != this.minValue) {
      this.absoluteMaxValue = this.maxValue;
      this.absoluteMinValue = this.minValue;
    }
  }

  getYByValue(value: number) {
    if (this.absoluteMinValue == null || this.absoluteMaxValue == null) {
      return 0;
    }
    return Math.min(this.svgHeight - (value - this.absoluteMinValue) / (this.absoluteMaxValue - this.absoluteMinValue) * this.svgHeight, this.svgHeight);
  }

  getPath(days: number[], values: number[]) {
    let lineFunction = d3.line<number>()
      .curve(d3.curveLinear)
      .x((d, i) => {
        return i * this.lengthX;
      })
      .y((d, i) => {
        return this.getYByValue(values[i]);
      });
    return lineFunction(days);
  }

  getPolygon(days: number[], values: number[]) {
    let lineFunction = d3.area<number>()
      .curve(d3.curveLinear)
      .x((d, i) => {
        return i * this.lengthX;
      })
      .y0(this.svgHeight)
      .y1((d, i) => {
        return this.getYByValue(values[i]);
      });
    return lineFunction(days);
  }

}
