<div class="template">
  <div class="template__icon" [ngStyle]="{background: iconBg, color: iconColor}">
    <span [ngClass]="icon"></span>
  </div>
  <div class="template__title">
    {{title}}
  </div>
  <div class="template__description">
    {{description}}
  </div>
  <div class="template__buttons">
    <app-button [color]="'primary'" (click)="create()">{{buttonName}}</app-button>
  </div>
</div>
