<div class="checkbox">
  <div class="checkbox__value" [ngClass]="value ? 'active' : ''">
    <ng-container *ngIf="value">
      <span class="icon-check"></span>
    </ng-container>
  </div>
  <div class="checkbox__name">
    <ng-content></ng-content>
  </div>
</div>
