import {Component, OnInit} from '@angular/core';
import {MessagingService} from '../../../services/messaging.service';
import {PopupMessage} from '../../../../alerts';

@Component({
  selector: 'app-popup-message',
  templateUrl: './popup-message.component.html',
  styleUrls: ['./popup-message.component.scss']
})
export class PopupMessageComponent implements OnInit {

  public data: PopupMessage;

  constructor(
    private messaging: MessagingService
  ) {
  }

  ngOnInit() {
    this.messaging.of(PopupMessage).subscribe(response => {
      setTimeout(() => {
        if (response.status) {
          this.data = response;
        } else {
          this.data = null;
        }
      });
    });
  }

}
