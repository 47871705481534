<div class="header">

  <div class="header__logo">
    <div class="logo">
      <img [src]="'/assets/images/logo.svg'" alt=""/>
    </div>
    <div class="title">
      <div class="title__first">Бюро национальной статистики</div>
      <div class="title__second">Агентства по стратегическому планированию и реформам Республики Казахстан</div>
    </div>
  </div>

  <div class="space"></div>

  <div class="header__navigation">
    <div class="header__navigation__item" [routerLink]="['/report', 'start']" [routerLinkActive]="'active'">
      <div class="header__navigation__item__name">
        <span class="item-name">Создать отчет</span>
      </div>
    </div>
    <div class="header__navigation__item" [routerLink]="['/report', 'top']" [routerLinkActive]="'active'">
      <div class="header__navigation__item__name">
        <span class="item-name">Топ</span>
      </div>
    </div>
    <div class="header__navigation__item" [routerLink]="['/about']" [routerLinkActive]="'active'">
      <div class="header__navigation__item__name">
        <span class="item-name">О проекте</span>
      </div>
    </div>
    <div class="header__navigation__item" [routerLink]="['/api']" [routerLinkActive]="'active'">
      <div class="header__navigation__item__name">
        <span class="item-name">API</span>
      </div>
    </div>
  </div>

  <div class="header__menu">
    <div class="header__menu__button">
      <span class="icon-menu"></span>
    </div>
  </div>

<!--  <div class="header__user">-->
<!--    <div class="header__user__info">-->
<!--      <div class="header__user__avatar" *ngIf="user">-->
<!--        <app-user-avatar [user]="user"></app-user-avatar>-->
<!--      </div>-->
<!--      <div class="header__user__name">-->
<!--        <div>{{user.full_name}}</div>-->
<!--        <div class="position">{{user.position}}</div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="header__navigation">-->
<!--    <div class="header__navigation__item" (click)="logout()">-->
<!--      <div class="header__navigation__item__name">-->
<!--        <span class="icon icon-log-out"></span>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

</div>
