<div class="content">
  <div class="sidebar">
    <div class="logo" routerLink="/">
      <img [src]="'/assets/images/logo.svg'" alt=""/>
    </div>
    <div class="form">
      <div class="form__title">{{"auth"|translate}}</div>
      <form [formGroup]="form" (ngSubmit)="auth()">
        <label>
          {{"login"|translate}}:
          <input class="form-control"
                 [placeholder]="'Введите логин'"
                 formControlName="username"
                 [(ngModel)]="username">
        </label>
        <label>
          {{"password"|translate}}:
          <input class="form-control"
                 [placeholder]="'enter_password'|translate"
                 type="password"
                 formControlName="password"
                 [(ngModel)]="password">
        </label>
        <div class="buttons">
          <app-button type="submit" [color]="'primary'" [disabled]="!form.valid" (click)="auth()">
            {{"sign_in"|translate}}
          </app-button>
        </div>
      </form>
    </div>
  </div>
  <div class="body">
    <div class="scene">
      <img [src]="'/assets/landing/illustrations/scene.svg'" alt=""/>
    </div>
    <div class="author">

    </div>
  </div>
</div>
