<app-admin></app-admin>
<div class="main-info">
  <table class="styled-table">
    <thead>
    <tr>
      <th>№</th>
      <th>{{'form.form_1.fio'|translate}}</th>
      <th>{{'form.form_1.place'|translate}}</th>
      <th>{{'form.form_1.city'|translate}}</th>
      <th>Описание</th>
      <th>{{'form.contacts'|translate}}</th>
      <th>{{'form.email'|translate}}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let t of data; let i = index">
      <td>{{t.id}}</td>
      <td>
        {{t.full_Name}}
      </td>
      <td>
        {{t.work_place}}
      </td>
      <td>
        {{t.city}}
      </td>
      <td>
        {{t.short_description}}
      </td>
      <td>
        {{t.contact_data}}
      </td>
      <td>
        {{t.email}}
      </td>
    </tr>
    </tbody>
  </table>
  <mat-paginator [length]="20"
                 [pageSize]="20"
                 aria-label="Select page" style="float: left">
  </mat-paginator>
  <button mat-button (click)="export()">Выгрузить</button>
</div>



