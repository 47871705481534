import {Component, Input, OnInit} from '@angular/core';

export interface IActionBarItem {
  name: string;
  routerLink: any[];
  access?: string[];
  badge?: boolean;
}

@Component({
  selector: 'app-actionbar',
  templateUrl: './actionbar.component.html',
  styleUrls: ['./actionbar.component.scss']
})
export class ActionbarComponent implements OnInit {

  @Input() bg: string;
  @Input() items: IActionBarItem[] = [];

  constructor() {
  }

  ngOnInit(): void {
  }

  getBackground() {
    if (this.bg) {
      return {
        'background': '#20222E url("' + this.bg + '") center center no-repeat',
        'background-size': 'cover !important'
      }
    }
  }

}
