import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrls: ['./dropdown-input.component.scss']
})
export class DropdownInputComponent implements OnInit {

  query: string = '';

  @Input() name: string = '';
  @Input() items: any[] = [];
  @Input() key: string = 'name';
  @Output() set = new EventEmitter<any>();

  showDropdown: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  setValue(event) {
    this.query = event;
    this.showDropdown = this.query.length > 0;
  }

  outputValue(i: any) {
    this.showDropdown = false;
    this.set.emit(i);
  }

}
