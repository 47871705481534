import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LandingComponent} from './components/landing/2022/landing.component';
import {AdminComponent} from './components/admin/admin.component';
import {AuthGuard} from '../../shared/core/guards/auth.guard';
import {MentorComponent} from './components/admin/mentor/mentor.component';
import {SponsorComponent} from './components/admin/sponsor/sponsor.component';
import {AuthComponent} from '../auth/components/auth/auth.component';
import {PartyComponent} from './components/admin/party/party.component';
import {PartyViewComponent} from './components/landing/party/party-view.component';
import {VoiceComponent} from './components/landing/voice/voice.component';
import {Landing3Component} from './components/landing/2024/landing.component';
import {Landing2Component} from './components/landing/2023-1/landing.component';

const routes: Routes = [
  {path: '', component: Landing3Component},
  {path: 'admin', component: AdminComponent, canActivate: [AuthGuard]},
  {path: 'admin/mentor', component: MentorComponent, canActivate: [AuthGuard]},
  {path: 'admin/sponsor', component: SponsorComponent, canActivate: [AuthGuard]},
  {path: 'admin/party', component: PartyComponent, canActivate: [AuthGuard]},
  {path: 'auth', component: AuthComponent},
  {path: 'party', component: PartyViewComponent},
  {path: 'voice', component: VoiceComponent},
  {path: '2022', component: LandingComponent},
  {path: '2023', component: Landing2Component},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DatathonRoutingModule {
}
