import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../data.service';
import {PartyModel} from '../../../../../shared/models/mentor';
import exportFromJSON from 'export-from-json';

@Component({
  selector: 'app-party',
  templateUrl: './party.component.html',
  styleUrls: ['./party.component.scss']
})
export class PartyComponent implements OnInit {

  constructor(private dataService: DataService) { }

  data: PartyModel[];
  version = 3;
  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.dataService.getParties(this.version).then(resp => {
      this.data = resp;
    });
  }

  export(){
    const data = [];
    this.data.forEach(z => {
      const objData = {} as any;
      objData.name = z.name;
      objData.educationalPlace = z.educationalPlace;
      objData.city = z.city;
      objData.contact_data = z.contact_data;
      objData.email = z.email;
      objData.members = [];
      z.members.forEach(x => {
        objData.members.push(x.name + ' - ' + x.programm + ' - ' + x.education);
      });
      data.push(objData);
    });
    const fileName = 'команды';
    const exportType =  exportFromJSON.types.xls;

    exportFromJSON({ data, fileName, exportType });
  }

}
