import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements  CanLoad, CanActivate {
  constructor(private router: Router) {

  }
  canLoad(route: Route): boolean {
    if (!localStorage.getItem('userInfo')){
      return false;
    }

    if ( !route.data || !route.data.permissions) {
      return true;
    }

    return false;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!localStorage.getItem('user')){
      return this.router.navigate(['/auth']);
    }
    const user = JSON.parse(localStorage.getItem('user'));
    if (this.tokenExpired(user.token)){
      return this.router.navigate(['/auth']);
    }
    return true;
  }

  private tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date()).getTime() / 1000)) >= expiry;
  }
}
