import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ProviderService} from '../../../services/provider.service';
import {IUser} from '../../../../modules/auth/models/user.model';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit, OnChanges {

  @Input() user: IUser;
  @Input() size: string = 'small' || 'middle' || 'large';

  url: string = '';
  name: string = '';
  bgColor: string = '';

  image: boolean = true;
  text: string = '';

  constructor() {

  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.name = this.user.full_name;
    this.url = this.user.profile?.avatar_url;
    this.bgColor = this.user.profile?.bg_color;
    if (this.url) {
      this.image = true;
    } else {
      this.image = false;
      this.text = ProviderService.getIconText(this.name);
    }
  }

  getStyle() {
    if (this.image) {
      return {
        background: 'url(' + this.url + ')'
      };
    } else {
      return {
        background: this.bgColor
      };
    }
  }

}
