<div class="section section-header" id="header">
  <div class="wrapper">
    <div class="left">
      <div class="logo-1">
        <img [src]="lang == 'ru' ? '/assets/images/data/j-3_ru.png' : '/assets/images/data/j-3_kz.png'" alt=""/>
      </div>
      <div class="logo-2">
        <img [src]="lang == 'ru' ? '/assets/images/data/j-4_ru.png' : '/assets/images/data/j-4_kz.png'" alt=""/>
      </div>

    </div>
    <div class="right">
      <div class="langGroup">
        <div class="lang" (click)="setLang('kk')" [ngClass]="lang == 'kk' ? 'active' : ''">
          КАЗ
        </div>
        <div class="lang" (click)="setLang('ru')" [ngClass]="lang == 'ru' ? 'active' : ''">
          РУС
        </div>
      </div>
      <div class="item" (click)="onClick('contacts')">{{'contacts.title'|translate}}</div>
      <div class="button" (click)="nav = true;">
        <span class="icon-menu"></span>
      </div>
    </div>
  </div>
</div>


<div class="section section-countdown">
  <div class="wrapper">
    <div class="section-title">
      {{'voice.pageName' | translate}}
    </div>
    <div class="section-title" *ngIf="lang=='ru'">
      <img [src]="'assets/images/voice-info_' + version + '.png'">
    </div>

    <div class="section-title" *ngIf="lang=='kk'">
      <img [src]="'assets/images/voice-info_' + version + '.png'">
    </div>

    <div class="section-body">
      <div class="dropdown" id="dropdown">
        <div class="selectedParty">
          <div class="partyMainInfo">
            <label class="partyName">{{party?.name}}</label>
            <hr>
            <label *ngIf="lang=='ru'" class="partyEducationPlace">{{party?.additionalFields.educationalPlaceNameRu}}</label>
            <label *ngIf="lang=='kk'" class="partyEducationPlace">{{party?.additionalFields.educationalPlaceNameKz}}</label>
          </div>
          <span class="icon-chevron-down"></span>
        </div>
        <div class="dropdown-content">
          <a (click)="changeParty(party)" *ngFor="let party of parties">{{party.name}}</a>
        </div>
      </div>
    </div>

  </div>
</div>


<div class="section section-parties">
  <div class="wrapper">
    <div class="section-title">
      {{'parties.members' | translate}}
    </div>
    <div class="section-body">

      <div class="parties">
        <div class="party" *ngFor="let f of party?.members">
          <div class="party__title">
            <img class="avatar"[src]=f?.photo.path>
            {{ f?.name }}
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<div class="section section-project">
  <div class="wrapper">
    <div class="section-title">
      {{'parties.project' | translate}}
    </div>
    <div class="section-body">

      <div class="project" *ngFor="let project of party?.projectFiles">
        <img [src]="'/assets/images/icons/'  + project?.logo + '.png'">
        <a [href]="project?.path" target="_blank">{{project.name}}</a>
      </div>
    </div>
  </div>
</div>


<!--<div class="section section-voice">-->
<!--  <div class="wrapper">-->
<!--    <div class="section-title">-->
<!--      {{'voice.title' | translate}}-->
<!--    </div>-->
<!--    <div class="voice">-->
<!--      <div class="rating">-->
<!--        {{voiceRating}}-->
<!--      </div>-->
<!--      <app-mat-star-rating [rating]="voiceRating"  (ratingUpdated)="onRatingChanged($event)"></app-mat-star-rating>-->
<!--      <button (click)="postRating()">{{'voice.send' | translate}}</button>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->


<div class="section section-footer" id="contacts">
  <div class="wrapper">
    <div class="wrapper-title">{{'contacts.title'|translate}}</div>
    <div class="phones">
      <a href="tel:+77172749831">8 (7172) 74-98-31</a>,
      <a href="tel:+77172749502">74-95-02</a>,
      <a href="tel:+77172749279">74-92-79</a>
    </div>
    <div class="email">
      {{'contacts.email'|translate}}:
      <a href="mailto:qazdatathon@gmail.com">qazdatathon@gmail.com</a>
    </div>
  </div>
</div>

<ng-container *ngIf="nav">
  <div class="navigation" (click)="nav = false; $event.stopPropagation()">
    <div class="side">
      <div class="langs">
        <div class="lang" (click)="setLang('ru')" [ngClass]="lang == 'ru' ? 'active' : ''">
          РУС
        </div>
        <div class="lang" (click)="setLang('kk')" [ngClass]="lang == 'kk' ? 'active' : ''">
          КАЗ
        </div>
      </div>
      <div class="menu">
        <div class="item" (click)="onClick('contacts')">{{'contacts.title'|translate}}</div>
      </div>
    </div>
  </div>
</ng-container>
