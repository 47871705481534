<div class="wrapper" (clickOutside)="showDropdown = false; query = ''">
  <label>
    <input class="form-control" [ngModel]="query" (ngModelChange)="setValue($event)" [placeholder]="name" />
  </label>
  <ng-container *ngIf="showDropdown">
    <div class="contextmenu">
      <div class="contextmenu__item" *ngFor="let i of items | search: query" (click)="outputValue(i)">
        {{i[key]}}
      </div>
    </div>
  </ng-container>
</div>


























