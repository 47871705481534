<div class="workspace" [ngStyle]="getBackground()">
  <div class="tabs" *ngIf="items.length > 0">
    <div class="items">
      <div class="item" *ngFor="let i of items" [routerLink]="i.routerLink" [routerLinkActive]="'active'">
        <div class="badge" *ngIf="i.badge"></div>
        <div class="name">
          {{i.name|translate}}
        </div>
        <div class="line"></div>
      </div>
      <ng-content select="[options]"></ng-content>
    </div>
    <ng-content select="[header]"></ng-content>
  </div>
  <div class="content">
    <div class="content__scrollbar">
      <ng-content select="[body]"></ng-content>
    </div>
  </div>
</div>
