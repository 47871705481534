import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FileSizePipe} from './pipes/file-size.pipe';
import {FilterByPipe} from './pipes/filter-by.pipe';
import {LeftTimePipe} from './pipes/left-time.pipe';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ClickOutsideModule} from 'ng-click-outside';
import {UserAvatarComponent} from './components/templates/user-avatar/user-avatar.component';
import {LoadingTemplateComponent} from './components/templates/loading-template/loading-template.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {EntryTemplateComponent} from './components/templates/entry-template/entry-template.component';
import {ConfirmDialogComponent} from './components/windows/confirm-dialog/confirm-dialog.component';
import {LineChartComponent} from './components/charts/line-chart/line-chart.component';
import {TranslateModule} from '@ngx-translate/core';
import {DropdownButtonComponent} from './components/templates/dropdown-button/dropdown-button.component';
import {DropdownButtonItemComponent} from './components/templates/dropdown-button/dropdown-button-item/dropdown-button-item.component';
import {SearchPipe} from './pipes/search.pipe';
import {DropdownInputComponent} from './components/templates/dropdown-input/dropdown-input.component';
import {CheckboxComponent} from './components/templates/checkbox/checkbox.component';
import {ButtonComponent} from './components/templates/button/button.component';
import {ActionbarComponent} from './components/templates/actionbar/actionbar.component';


@NgModule({
    declarations: [
        FileSizePipe,
        FilterByPipe,
        LeftTimePipe,
        UserAvatarComponent,
        LoadingTemplateComponent,
        EntryTemplateComponent,
        ConfirmDialogComponent,
        LineChartComponent,
        DropdownButtonComponent,
        DropdownButtonItemComponent,
        SearchPipe,
        DropdownInputComponent,
        CheckboxComponent,
        ButtonComponent,
        ActionbarComponent
    ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ClickOutsideModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    TranslateModule
  ],
    exports: [
        FilterByPipe,
        FileSizePipe,
        LeftTimePipe,
        UserAvatarComponent,
        LoadingTemplateComponent,
        EntryTemplateComponent,
        ConfirmDialogComponent,
        LineChartComponent,
        DropdownButtonComponent,
        DropdownButtonItemComponent,
        SearchPipe,
        DropdownInputComponent,
        ButtonComponent,
        ActionbarComponent,
        CheckboxComponent
    ],
  entryComponents: [
    ConfirmDialogComponent
  ]

})
export class SharedModule {
}
