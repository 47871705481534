import {Component, ElementRef, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ViewportScroller} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {DataService} from '../../../data.service';
import {MessagingService} from '../../../../../shared/services/messaging.service';
import { PartyModel} from '../../../../../shared/models/mentor';
import {MatTable} from '@angular/material/table';
import {AlertMessage, PopupMessage} from "../../../../../alerts";

@Component({
  selector: 'app-party',
  templateUrl: './party-view.component.html',
  styleUrls: ['./party-view.component.scss']
})
export class PartyViewComponent implements OnInit {

  constructor(
    private viewportScroller: ViewportScroller,
    private translate: TranslateService,
    private dataService: DataService,
    private messaging: MessagingService, private activatedRoute: ActivatedRoute
  ) {
  }

  public lang: 'ru' | 'kk' = 'kk';

  nav: boolean;
  party: PartyModel;
  isVoice: boolean;
  partyId: number;
  voiceRating: number;


  @ViewChild(MatTable) tablePartyMembers: MatTable<any>;
  @ViewChild('photoBase64') memberPhotoFile: ElementRef;
  @ViewChild('fileBase64') memberEducationFile: ElementRef;

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params.partyId;
      this.dataService.getPartyById(this.partyId).then(resp => {
        this.party = resp;
      });
      if (params.isVoice){
        this.isVoice = true;
        this.checkVoice();
      }
    });
    const lang = localStorage.getItem('lang');
    if (lang) {
      this.setLang(lang);
    }
    this.translate.setDefaultLang('kk');
  }

  onClick(elementId: string): void {
    this.nav = false;
    this.viewportScroller.scrollToAnchor(elementId);
  }


  setLang(lang: any) {
    this.lang = lang;
    this.translate.use(lang);
    localStorage.setItem('lang', this.lang);
  }

  checkVoice(){
    const rating = localStorage.getItem('voice' + this.partyId);
    if (rating){
        this.voiceRating = parseInt(rating, 0);
    }
  }

  onRatingChanged($event: number) {
    this.voiceRating = $event;
  }
  postRating(){
    const rating = localStorage.getItem('voice' + this.partyId);
    if (!rating){
      this.dataService.postRating({rating: this.voiceRating, partyId: this.partyId}).then(resp => {
      if (resp){
        localStorage.setItem('voice' + this.partyId, this.voiceRating.toString());
        this.messaging.publish(new AlertMessage(this.lang === 'ru' ? 'Ваш голос сохранен' : 'Өтінімді толтырыңыз', [], true, false));
      }
      else {
        // tslint:disable-next-line:max-line-length
        this.messaging.publish(new AlertMessage(this.lang === 'ru' ? 'Вы уже голосовали за эту команду' : 'Өтінімді толтырыңыз', [], true, true));
      }
    });
    }
    else {
      this.voiceRating = parseInt(rating, 0);
      // tslint:disable-next-line:max-line-length
      this.messaging.publish(new AlertMessage(this.lang === 'ru' ? 'Вы уже голосовали за эту команду' : 'Өтінімді толтырыңыз', [], true, true));
    }
  }
}

