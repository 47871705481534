export class AlertMessage {
  constructor(public title: string, public messages: string[], public status: boolean, public isDanger = false) {
  }
}

export class PopupMessage {
  constructor(public message: string, public status: boolean) {
  }
}

export class ScreenLoadingMessage {
  constructor(public message: string, public status: boolean) {
  }
}

export class ReloadMessage {
  constructor(public command: string) {
  }
}
