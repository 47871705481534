import { Injectable } from '@angular/core';
import {MainService} from "../../shared/services/main.service";
import {HttpClient} from "@angular/common/http";
import {ApiService} from "../../shared/services/api.service";
import {PartyModel, PartyVoiceModel} from "../../shared/models/mentor";

@Injectable({
  providedIn: 'root'
})
export class DataService extends MainService {

  constructor(http: HttpClient, api: ApiService) {
    super(http, api);
  }

  setForm1(obj: any): Promise<any> {
    return this.post('api/Mentor/', obj);
  }

  setForm2(obj: any): Promise<any> {
    return this.post('api/Sponsor', obj);
  }

  getMentors(version: number): Promise<any> {
    return this.get('api/Mentor/Version/' + version, null);
  }

  getSponsors(version: number): Promise<any> {
    return this.get('api/Sponsor/Version/' + version, null);
  }

  setForm3(obj: any) {
    return this.post('api/Party', obj);
  }

  getParties(version: number): Promise<PartyModel[]> {
    return this.get('api/Party/Version/' + version, null);
  }

  getPartyById(id: number) {
    return this.get('api/Party/' + id, null);
  }

  postRating(request: PartyVoiceModel) {
    return this.post('api/Party/SaveVoice', request);
  }
}
