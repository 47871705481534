<div class="workspace">

  <div class="workspace__scrollbar">

    <div class="bg">
      <img [src]="'/assets/video/bg.png'" alt="" />
    </div>

    <div class="header">
      <div class="container">
        <div class="row flex align-items-center justify-content-between">
          <div class="align-left flex align-items-center">
            <div class="logo">
              <img [src]="'/assets/images/logo_black.svg'" alt="" />
            </div>
            <div class="header">Бизнес приложение</div>
          </div>
          <div class="align-right">
            <a href="https://1docs.kz" target="_blank">
              <img [src]="'/assets/images/1docs-logo.svg'" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="intro">
      <div class="container">
        <div class="row flex column align-items-center justify-content-center sm-7">
          <div class="title">
            ORTA ecosystem.
          </div>
          <div class="description">
            ИС «ORTA ecosystem» - это комплекс программных обеспечений для организации
            и автоматизации работы с внутренней и внешней документацией и проектами.
          </div>
          <app-button [color]="'primary'" [routerLink]="['/login']">Приступить к работе</app-button>
        </div>
      </div>
    </div>

    <div class="scene">
      <div class="container">
        <div class="row sm-7">
          <img [src]="'/assets/landing/illustrations/scene.svg'" alt="">
        </div>
      </div>
    </div>

    <div class="modules">
      <div class="container">
        <div class="row flex">
          <div class="module">
            <div class="module__icon">
              <img [src]="'/assets/landing/icons/book.svg'" alt=""/>
            </div>
            <div class="module__title">E-HR</div>
            <div class="module__description">
              Автоматизированная система управления кадровым делопроизводством.
            </div>
          </div>
          <div class="module">
            <div class="module__icon">
              <img [src]="'/assets/landing/icons/docs.svg'" alt=""/>
            </div>
            <div class="module__title">E-DOCUMENT</div>
            <div class="module__description">
              Cистема электронного документооборота с конструктором бизнес процессов.
            </div>
          </div>
          <div class="module">
            <div class="module__icon">
              <img [src]="'/assets/landing/icons/auction.svg'" alt=""/>
            </div>
            <div class="module__title">E-OFFICE</div>
            <div class="module__description">
              Виртуальная канцелярия, обработка входящей / исходящей корреспонденции.
            </div>
          </div>
        </div>
        <div class="row flex">
          <div class="module">
            <div class="module__icon">
              <img [src]="'/assets/landing/icons/project.svg'" alt=""/>
            </div>
            <div class="module__title">E-PROJECT</div>
            <div class="module__description">
              Система управления проектами по современным моделям управления.
            </div>
          </div>
          <div class="module">
            <div class="module__icon">
              <img [src]="'/assets/landing/icons/waves.svg'" alt=""/>
            </div>
            <div class="module__title">E-UNIT</div>
            <div class="module__description">
              Система облегчающая ежедневную рутинную работу сотрудников.
            </div>
          </div>
          <div class="module">
            <div class="module__icon">
              <img [src]="'/assets/landing/icons/dispatcher.svg'" alt=""/>
            </div>
            <div class="module__title red">ИНТЕГРАЦИИ</div>
            <div class="module__description">
              Множество взаимосвязанных приложении<br/>и сервисов.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="quote">
      <div class="container">
        <div class="row flex column align-items-center justify-content-center sm-7">
          <div class="header">
            Лучше во всех отношениях
          </div>
          <div class="description">
            Первый шаг на пути к цифровой трансформации в компании. Оно позволяет в разы
            увеличить эффективность работы сотрудников и масштабировать этот успех до уровня
            крупной компании в минимальные сроки.
          </div>
        </div>
      </div>
    </div>

    <div class="testinomial">
      <div class="container">
        <div class="row flex align-items-center">
          <div class="box margin-right">
            <div class="title">
              Вам больше <br/>не придется использовать <br/>отдельные приложения
            </div>
            <div class="description">
              <p>Добавьте доски Канбан, диаграммы Ганта, календари и многое другое вместе с документами,
                беседами, напоминаниями, электронными таблицами и встраиваемыми материалами,
                чтобы хранить всю свою работу в одном месте.
              </p>
            </div>
            <app-button [color]="'primary'">Читать дальше</app-button>
          </div>
          <div class="box">
            <img [src]="'/assets/landing/illustrations/scene-3.svg'" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="testinomial">
      <div class="container">
        <div class="row flex align-items-center">
          <div class="box">
            <img [src]="'/assets/landing/illustrations/scene-2.svg'" alt="">
          </div>
          <div class="box margin-left">
            <div class="title">
              Вы будете знать, <br/>над чем все работают
            </div>
            <div class="description">
              <p>
                Просматривайте профили других людей, чтобы увидеть, над чем они работают и над
                чем будут работать дальше, для максимальной и беспрецедентной прозрачности.
              </p>
            </div>
            <app-button [color]="'primary'">Читать дальше</app-button>
          </div>
        </div>
      </div>
    </div>

    <div class="counters">
      <div class="container">
        <div class="row flex">
          <div class="counter">
            <div class="counter__value">98%</div>
            <div class="counter__description">
              Средняя оценка удовлетворенности, полученная в прошлом году.
            </div>
          </div>
          <div class="counter">
            <div class="counter__value">24/7</div>
            <div class="counter__description">
              Наша служба поддержки находится здесь, в чате или по электронной почте.
            </div>
          </div>
          <div class="counter">
            <div class="counter__value">220k+</div>
            <div class="counter__description">
              Действий производятся пользователями в нашей системе еженедельно.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="integrations">
      <div class="container">
        <div class="row">

        </div>
      </div>
    </div>

    <div class="customers">

    </div>

    <div class="get-it">
      <figure>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 43.4">
          <path
            d="M0,23.3c0,0,405.1-43.5,697.6,0c316.5,1.5,108.9-2.6,480.4-14.1c0,0,139-12.2,458.7,14.3 c0,0,67.8,19.2,283.3-22.7v35.1H0V23.3z">
          </path>
        </svg>
      </figure>
      <div class="container">
        <div class="row flex align-items-center">
          <div class="box margin-right">
            <div class="sub-title">ORTA ecosystem</div>
            <div class="title">
              Начните за несколько минут
            </div>
            <div class="description">
              Заполните заявку на предоставление демо-доступа к системе и начните работать прямо сейчас!
            </div>
            <app-button [color]="'primary'" [routerLink]="['/login']">Приступить к работе</app-button>
          </div>
          <div class="box bottom">
            <img [src]="'/assets/landing/illustrations/scene-4.svg'" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="container">
        <div class="row flex justify-content-between bottomLine">
          <div class="box">
            <div class="box__title">
              Адрес
            </div>
            <div class="box__body">
              г. Нур-Султан, ул. Момышулы Б., здание 2/11, офис 306
            </div>
          </div>
          <div class="box">
            <div class="box__title">
              Почта
            </div>
            <div class="box__body">
              <div>
                <a href="mailto:info@1docs.kz">info@1docs.kz</a>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="box__title">
              Телефон
            </div>
            <div class="box__body">
              <div>
                <a href="tel:+77750950077">+7 (775) 095 00 77</a>
              </div>
            </div>
          </div>
        </div>
        <div class="row flex align-items-center justify-content-center">
          <div class="copyright">
            Copyright © ТОО 1Docs 2020-2021. Все права защищены.
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
