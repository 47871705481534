import {Component, Input, Output, OnInit, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-dropdown-button-item',
  templateUrl: './dropdown-button-item.component.html',
  styleUrls: ['./dropdown-button-item.component.scss']
})
export class DropdownButtonItemComponent implements OnInit {

  @Input() item: any;
  @Input() key: string = 'name';
  @Input() isActive: boolean;
  @Output() set = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  outputValue(item) {
    this.set.emit(item);
  }
}
