import {Component, ElementRef, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ViewportScroller} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {DataService} from '../../../data.service';
import {MessagingService} from '../../../../../shared/services/messaging.service';
import { PartyModel} from '../../../../../shared/models/mentor';
import {MatTable} from '@angular/material/table';
import {AlertMessage} from '../../../../../alerts';

@Component({
  selector: 'app-party',
  templateUrl: './voice.component.html',
  styleUrls: ['./voice.component.scss']
})
export class VoiceComponent implements OnInit {

  constructor(
    private viewportScroller: ViewportScroller,
    private translate: TranslateService,
    private dataService: DataService,
    private messaging: MessagingService, private activatedRoute: ActivatedRoute
  ) {
  }

  public lang: 'ru' | 'kk' = 'kk';

  nav: boolean;
  party: PartyModel;
  voiceRating: number;
  parties: PartyModel[] = [];

  version: number;


  @ViewChild(MatTable) tablePartyMembers: MatTable<any>;
  @ViewChild('photoBase64') memberPhotoFile: ElementRef;
  @ViewChild('fileBase64') memberEducationFile: ElementRef;

  ngOnInit(): void {
    this.getAllParties();
    const lang = localStorage.getItem('lang');
    if (lang) {
      this.setLang(lang);
    }
    this.translate.setDefaultLang('kk');
  }

  onClick(elementId: string): void {
    this.nav = false;
    this.viewportScroller.scrollToAnchor(elementId);
  }


  setLang(lang: any) {
    this.lang = lang;
    this.translate.use(lang);
    localStorage.setItem('lang', this.lang);
  }

  checkVoice(){
    const rating = localStorage.getItem('voice' + this.party.id);
    if (rating){
        this.voiceRating = parseInt(rating, 0);
    }
    else{
      this.voiceRating = 0;
    }
  }

  onRatingChanged($event: number) {
    this.voiceRating = $event;
  }
  postRating(){
    this.messaging.publish(new AlertMessage(this.lang === 'ru' ? 'Голосование окончено' : 'Дауыс беру аяқталды', [], true, true));
    // const rating = localStorage.getItem('voice' + this.party.id);
    // if (!rating){
    //   this.dataService.postRating({rating: this.voiceRating, partyId: this.party.id}).then(resp => {
    //   if (resp){
    //     localStorage.setItem('voice' + this.party.id, this.voiceRating.toString());
    //     this.messaging.publish(new AlertMessage(this.lang === 'ru' ? 'Ваш голос сохранен' : 'Ваш голос сохранен', [], true, false));
    //   }
    //   else {
    //     // tslint:disable-next-line:max-line-length
    // tslint:disable-next-line:max-line-length
    //     this.messaging.publish(new AlertMessage(this.lang === 'ru' ? 'Вы уже голосовали за эту команду' : 'Вы уже голосовали за эту команду', [], true, true));
    //   }
    // });
    // }
    // else {
    //   this.voiceRating = parseInt(rating, 0);
    //   // tslint:disable-next-line:max-line-length
    // tslint:disable-next-line:max-line-length
    //   this.messaging.publish(new AlertMessage(this.lang === 'ru' ? 'Вы уже голосовали за эту команду' : 'Вы уже голосовали за эту команду', [], true, true));
    // }
  }

  async changeParty(party: PartyModel) {
    this.party = party;
    this.checkVoice();
    document.getElementById('dropdown').className = 'hiddenClass';
    await this.delay(250);
    document.getElementById('dropdown').className = 'dropdown';
  }
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  private getAllParties() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.version = params.version;
      this.dataService.getParties(this.version).then(res => {
        this.parties = res.filter(z => z.projectFiles.length > 0);
        if (params.partyId){
          // tslint:disable-next-line:triple-equals
          this.party = this.parties.filter(z => z.id == params.partyId)[0];
          this.checkVoice();
        }
        else{
          this.party = this.parties[0];
          this.checkVoice();
        }
      });
    });
  }

  download(url, filename) {
    console.log(url , filename);
    fetch(url).then((t) => {
      return t.blob().then((b) => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(b);
          a.setAttribute('download', filename);
          a.click();
        }
      );
    });
  }
}

