import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../data.service';
import {MentorModel} from '../../../../../shared/models/mentor';
import exportFromJSON from 'export-from-json';

@Component({
  selector: 'app-mentor',
  templateUrl: './mentor.component.html',
  styleUrls: ['./mentor.component.scss']
})
export class MentorComponent implements OnInit {

  constructor(private dataService: DataService) { }

  data: MentorModel[];
  version = 3;
  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.dataService.getMentors(this.version).then(resp => {
      this.data = resp;
    });
  }

  export(){
    const data = this.data;
    const fileName = 'mentors';
    const exportType =  exportFromJSON.types.xls;

    exportFromJSON({ data, fileName, exportType });
  }

}
