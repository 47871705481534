import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {DatathonRoutingModule} from './datathon-routing.module';
import {LandingComponent} from './components/landing/2022/landing.component';
import {Landing2Component} from './components/landing/2023-1/landing.component';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../../shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AdminComponent } from './components/admin/admin.component';
import { MentorComponent } from './components/admin/mentor/mentor.component';
import { SponsorComponent } from './components/admin/sponsor/sponsor.component';
import {MatButtonModule} from '@angular/material/button';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {PartyComponent} from './components/admin/party/party.component';
import {PartyViewComponent} from './components/landing/party/party-view.component';
import {MatCardModule} from '@angular/material/card';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { StarRatingComponent } from './components/landing/star-rating/star-rating.component';
import {VoiceComponent} from './components/landing/voice/voice.component';
import {Landing3Component} from "./components/landing/2024/landing.component";


@NgModule({
  declarations: [
    LandingComponent,
    Landing2Component,
    Landing3Component,
    AdminComponent,
    MentorComponent,
    SponsorComponent,
    PartyComponent,
    PartyViewComponent,
    StarRatingComponent,
    VoiceComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    DatathonRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatPaginatorModule,
    MatTableModule,
    MatIconModule,
    MatCardModule,
    FormsModule,
    MatTooltipModule,
    MatSnackBarModule
  ]
})
export class DatathonModule {
}
