export const FAQ = {
  ru: [{
    question: 'Как подать заявку для участия в конкурсе?',
    answer: 'Заявки на участие в конкурсе в качестве участника подаются в соответствующем разделе сайта «Заявки».',
    isOpen: true
  },  {
    question: 'Когда и каким способом получим наборы данных?',
    answer: 'Датасеты будут направлены на Вашу корпоративную почту команды в течении дня 10 ноября т.г.',
    isOpen: false
  }, {
    question: 'Какие темы для исследования могу выбрать?',
    answer: 'Выбор темы свободный. Вместе с тем, проект должен решать определенные социально-экономические вызовы и в работах  обязательно применение наборов синтетических данных, представленных  Организаторами.',
    isOpen: false
  }, {
    question: 'Что подразумевает собой наборы синтетических данных?',
    answer: 'Наборы синтетические данных - это программно сгенерированные данные на основе фактических данных и учитывающие их паттерны и распределение, при этом обеспечивая конфиденциальность данных',
    isOpen: false
  }, {
    question: 'Где будет проходить финал?',
    answer: 'Финал будет проходить в online/offline формате на платформе Astana Hub. Объявление о месте проведения финала сообщается на сайте конкурса.',
    isOpen: false
  }],
  kk: [{
    question: 'Конкурсқа өтінімді қалай беруге болады?',
    answer: 'Конкурсқа қатысушы ретінде өтінімдер «Өтінімдер» сайтының тиісті бөлімі арқылы беріледі.',
    isOpen: true
  },  {
    question: 'Деректер жинағын қашан және қалай аламыз?',
    answer: 'Деректер жинағы ағымдағы жылдың 10 қарашасында бір күн ішінде команданың корпоративтік поштасына жіберіледі.',
    isOpen: false
  }, {
    question: 'Зерттеу үшін қандай тақырыптарды таңдауға болады?',
    answer: 'Тақырыпты таңдау еркін түрде. Сонымен қатар,   белгілі әлеуметтік-экономикалық сын-тегеуіріндерді шешуі тиіс және жұмыстарда ұйымдастырушылар ұсынған синтетикалық деректер жинағының біреуі қолданылуы керек.',
    isOpen: false
  }, {
    question: 'Синтетикалық деректер жинағы нені білдіреді?',
    answer: 'Синтетикалық деректер жинақтары – бұл деректердің құпиялылығын қамтамасыз ете отырып, олардың үлгілері мен таралуын ескере отырып, нақты деректерге негізделген бағдарламалық түрде жасалған деректер.',
    isOpen: false
  }, {
    question: 'Финал қай жерде өтеді?',
    answer: 'Финал Astana Hub платформасында online/offline форматында өтеді. Финалдың өтетін орны туралы хабарландыру конкурстың сайтында хабарланады.',
    isOpen: false
  }]
};

export interface ITable {
  ru: ITableRow[],
  kk: ITableRow[]
};

export interface ITableRow {
  number: number;
  title: string;
  date: string;
  items: string[];
}

export const TABLE: ITable = {
  ru: [{
    number: 1,
    title: 'Объявление конкурса',
    date: '30 октября',
    items: [
      'объявление конкурса в социальных сетях;',
      'рассылка приглашения заинтересованным сторонам и широкой аудитории в социальных сетях.'
    ]
  }, {
    number: 2,
    title: 'Запуск конкурса',
    date: '6 ноября',
    items: [
      'начало приема заявок',
    ]
  }, {
    number: 3,
    title: 'Подача заявок',
    date: '6-9 ноября',
    items: [
      'заполнение онлайн-формы на сайте datathon.kz командами.'
    ]
  }, {
    number: 4,
    title: 'Подготовительная фаза',
    date: '10 ноября',
    items: [
      'онлайн открытие конкурса',
    ]
  }, {
    number: 5,
    title: 'Основная фаза',
    date: 'С 10 по 26 ноября',
    items: [
    ]
  }, {
    number: null,
    title: 'Оглашение ключевых задач необходимых к решению в ходе конкурса',
    date: '10 ноября',
    items: [
      'предоставление доступа к данным'
    ]
  }, {
    number: null,
    title: 'Подготовка командами конкурсного проекта',
    date: '15 ноября',
    items: [
      'Результат 1 (2-3 слайда): Дескриптивная аналитика датасетов (2-3 слайдов)'
    ]
  }, {
    number: null,
    title: null,
    date: '17 ноября',
    items: [
      'промежуточные встречи с командами  в формате онлайн Workshop 1'
    ]
  }, {
    number: null,
    title: null,
    date: '18-20 ноября',
    items: [
      'Формирование гипотез. Проработка методологии Тестирование гипотез командами'
    ]
  }, {
    number: null,
    title: null,
    date: '21 ноября',
    items: [
      'Результат 2 (2-3 слайда): Описание гипотез, сформированные первичные решения'
    ]
  }, {
    number: null,
    title: null,
    date: '24 ноября',
    items: [
      'промежуточные встречи с командами  в формате онлайн Workshop 2'
    ]
  }, {
    number: null,
    title: null,
    date: '24-26 ноября',
    items: [
      'Работа над гипотезами, финализация дашбордов'
    ]
  }, {
    number: null,
    title: 'Сдача финальных работ',
    date: '27 ноября',
    items: []
  }, {
      number: 6,
      title: 'Допуск к финалу. Проверка работ Организаторами на соответствие минимальным требованиям:',
      date: '28 ноября',
      items: [
        'соответствие работ целям проекта',
        'применение представленных датасетов',
        'использование инструментов анализа и визуализации данных',
        'презентация проекта (сведения о работе, авторы проекта, детали проекта, выводы).'
      ]
    }, {
    number: 7,
    title: 'Финал',
    date: '29 ноября',
    items: [
      'презентация и защита проектных работ в режиме online/offline по выбору команды (регламент до 15-минут)',
      'сессия вопросов и ответов между жюри и участниками',
      'подведение итогов оценки жюри',
      'торжественное награждение победителей'
    ]
  }],
  kk: [{
    number: 1,
    title: 'Конкурсты туралы хабарландыру',
    date: '30 қазан',
    items: [
      'әлеуметтік желілерде конкурс туралы хабарландыру;',
      'қатысуға мүдделі тараптарға және кең аудиторияға әлеуметтік желілер арқылы шақырулар жіберу.'
    ]
  }, {
    number: 2,
    title: 'Конкурстың басталуы',
    date: '6 қараша',
    items: [
      'өтінімдерді қабылдауды бастау'
    ]
  }, {
    number: 3,
    title: 'Өтінім беру',
    date: '6-9 қараша күндері',
    items: [
      'командалардың datathon.kz сайтында онлайн-нысандарды толтыруы.'
    ]
  }, {
    number: 4,
    title: 'Дайындық кезеңі',
    date: '10 қараша',
    items: [
      'конкурстың салтанатты ашылуы',
    ]
  }, {
    number: 5,
    title: 'Негізгі кезең',
    date: '10-26 қараша күндері',
    items: [
    ]
  }, {
    number: null,
    title: 'Конкурс барысында шешу қажет негізгі міндеттерді жария ету',
    date: '10 қараша',
    items: [
      'деректерге қолжетімділікті ұсыну'
    ]
  }, {
    number: null,
    title: 'Командалардың конкурстық жобаны дайындауы',
    date: '15 қараша',
    items: [
      '1-нәтиже (2-3 слайд): Деректер жинағының дескриптивті аналитикасы'
    ]
  }, {
    number: null,
    title: null,
    date: '17 қараша',
    items: [
      'командалармен онлайн форматтағы аралық кездесулер. Workshop 1'
    ]
  }, {
    number: null,
    title: null,
    date: '18-20 қараша күндері',
    items: [
      'Гипотезаларды қалыптастыру. Әдіснаманы пысықтау. Командалардың гипотезаларды тестілеуі'
    ]
  }, {
    number: null,
    title: null,
    date: '21 қараша',
    items: [
      '2-нәтиже(2-3 слайд): гипотезалардың сипаттамасы, қалыптасқан бастапқы шешімдер'
    ]
  }, {
    number: null,
    title: null,
    date: '24 қараша',
    items: [
      'командалармен онлайн форматтағы аралық кездесулер. Workshop 2'
    ]
  }, {
    number: null,
    title: null,
    date: '24-26 қараша күндері',
    items: [
      'Гипотезалар бойынша жұмыс, дашбордтарды аяқтау'
    ]
  }, {
    number: null,
    title: 'Қорытынды жұмыстарды тапсыру',
    date: '27 қараша',
    items: []
  }, {
    number: 6,
    title: 'Финалға өткізу. Ұйымдастырушыларының жұмыстарды келесі минималды талаптарға сәйкестігін тексеруі:',
    date: '28 қараша',
    items: [
      'жұмыстардың жоба мақсаттарына сәйкестігі',
      'ұсынылған деректер жинағын қолдануы',
      'деректерді талдау және визуализация құралдарын пайдалануы',
      'жоба презентациясы (жоба туралы мәліметтер, жоба авторлары, жобаның негізгі тұстары, қорытындылар)'
    ]
  }, {
    number: 7,
    title: 'Финал',
    date: '29 қараша',
    items: [
      'команданың таңдауы бойынша online/offline режимде жоба жұмыстарын презентациялау және қорғау (регламент 15 минутқа дейін)',
      'қазылар алқасы мен қатысушылар арасындағы сұрақ-жауап сессиясы',
      'қазылар алқасының бағалауы бойынша қорытынды шығаруы',
      'жеңімпаздарды салтанатты түрде марапаттау'
    ]
  }]
};
