<div class="wrapper" (clickOutside)="showDropdown = false">
  <app-button (click)="toggle()" [textAlign]="'left'">
    <ng-container *ngIf="icon">
      <span [ngClass]="icon"></span>
    </ng-container>
    {{name}}
  </app-button>
  <ng-container *ngIf="showDropdown">
    <div class="contextmenu" [ngClass]="position">
      <ng-container *ngIf="items.length == 0">
        <ng-content></ng-content>
      </ng-container>
      <ng-container *ngIf="items.length > 0">
        <div class="contextmenu__item"
             *ngFor="let i of items"
             [ngClass]="activeId == i.id ? 'active' : ''"
             (click)="outputValue(i)">
          {{i[key]}}
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
