import {Injectable} from '@angular/core';
import {MainService} from '../../../shared/services/main.service';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../../../shared/services/api.service';
import {IUser} from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService extends MainService {

  constructor(http: HttpClient, api: ApiService) {
    super(http, api);
  }

  getMe(): Promise<IUser[]> {
    return this.get('/api/auth/users/me/', {});
  }


}
