import {Injectable} from '@angular/core';
import {MainService} from './main.service';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProviderService extends MainService {

  products;

  constructor(http: HttpClient, api: ApiService) {
    super(http, api);
  }

  static getIconText(name: string) {
    const array = name.split(' ');
    let str = '';
    array.forEach(a => {
      str += a.charAt(0);
    });
    return str.slice(0, 2);
  }

  static getEndOfNumber(n, titles) {
    return titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
  }

  static getUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return 'U' + v.toString(16);
    });
  }

  parsing(id: number) {
    return this.http.post<any>(`https://leroymerlin.kz/api/internal/vue/catalogue_section_listing/${id}/`, {
      perPage: 10000,
      currentSectionLevel: 2
    }).pipe(map(response => {
      return response;
    }));
  }

}
