<div class="section section-header" id="header">
  <div class="wrapper">
    <div class="left">
<!--      <div class="logo-1">-->
<!--        <a href="/#/admin/mentor">Менторы</a>-->
<!--      </div>-->
<!--      <div class="logo-2">-->
<!--        <a href="/#/admin/sponsor">Спонсоры</a>-->
<!--      </div>-->
      <div class="logo-1">
        <a href="/#/admin/party">Команды</a>
      </div>
    </div>
    <div class="right">
    </div>
  </div>
</div>
