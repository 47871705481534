import {Component, OnInit} from '@angular/core';
import {MessagingService} from '../../../services/messaging.service';
import {AlertMessage} from '../../../../alerts';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit {

  public data: AlertMessage;

  constructor(
    private messaging: MessagingService
  ) {
  }

  ngOnInit() {
    this.messaging.of(AlertMessage).subscribe(message => {
      console.log(message);
      this.data = message;
      if (!this.data.isDanger) {
        setTimeout(() => {
          this.data = null;
        }, 1500);
      }
    });
  }

}
