<ng-container *ngIf="data">
  <div class="window">
    <div class="template">
      <div class="template__icon">
        <span class="icon-x"></span>
      </div>
      <div class="template__title">
        {{"notification"|translate}}
      </div>
      <div class="template__description">
        {{data.message}}
      </div>
      <div class="template__buttons">
        <app-button [color]="'danger'" (click)="data = null">
          {{"close"|translate}}
        </app-button>
      </div>
    </div>
  </div>
</ng-container>
