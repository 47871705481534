import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MainTemplateComponent} from './shared/components/construction/main-template/main-template.component';
import {AuthModule} from './modules/auth/auth.module';
import {MainHeaderComponent} from './shared/components/construction/main-header/main-header.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AlertMessageComponent} from './shared/components/messages/alert-message/alert-message.component';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {PopupMessageComponent} from './shared/components/windows/popup-message/popup-message.component';
import {JwtInterceptor} from './modules/auth/helpers/jwt.interceptor';
import {ErrorInterceptor} from './modules/auth/helpers/error.interceptor';
import {FormsModule} from '@angular/forms';
import {ScreenLoadingMessageComponent} from './shared/components/windows/screen-loading-message/screen-loading-message.component';
import {LandingTemplateComponent} from './shared/components/construction/landing-template/landing-template.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {SharedModule} from './shared/shared.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DatathonModule} from './modules/datathon/datathon.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    MainTemplateComponent,
    MainHeaderComponent,
    AlertMessageComponent,
    PopupMessageComponent,
    ScreenLoadingMessageComponent,
    LandingTemplateComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    HttpClientModule,
    AuthModule,
    MatNativeDateModule,
    DatathonModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    MainTemplateComponent
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: MAT_DATE_LOCALE, useValue: 'ru-RU'},
    {provide: MatDialogRef, useValue: {}},
    {provide: MAT_DIALOG_DATA, useValue: {}}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
