<div class="template">
  <div class="template__icon">
    <span class="icon-trash-2"></span>
  </div>
  <div class="template__title">
    {{message}}
  </div>
  <div class="template__description">

  </div>
  <div class="template__buttons">
    <app-button mat-dialog-close>{{"cancel"|translate}}</app-button>
    <app-button [color]="'danger'" (click)="accept()">{{"delete"|translate}}</app-button>
  </div>
</div>
