import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss']
})
export class DropdownButtonComponent implements OnInit {

  @Input() icon: string = '';
  @Input() name: string = '';
  @Input() items: any[] = [];
  @Input() activeId: number = null;
  @Input() key: string = 'name';
  @Input() position: 'left' | 'right' = 'left';
  @Output() set = new EventEmitter<any>();
  showDropdown: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  outputValue(i: any) {
    this.showDropdown = false;
    this.set.emit(i);
  }

  toggle() {
    this.showDropdown = !this.showDropdown;
  }

}
