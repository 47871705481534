<ng-container *ngIf="data">
  <div class="window" [ngClass]="data.isDanger ? 'danger' : '' ">
    <div class="template">
      <div class="template__icon" [ngClass]="data.isDanger ? 'danger' : '' ">
        <span *ngIf="data.isDanger" [ngClass]="'icon-surprise'"></span>
        <span *ngIf="!data.isDanger" [ngClass]="'icon-check'"></span>
      </div>
      <div class="template__title">
        {{data.title}}
      </div>
      <div class="template__description">
        <div *ngFor="let m of data.messages">
          {{m}}
        </div>
      </div>
      <div class="template__buttons">
        <app-button [color]="'primary'" (click)="data = null">Закрыть</app-button>
      </div>
    </div>
  </div>
</ng-container>
