import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../../modules/auth/services/auth.service';
import {IUser} from '../../../../modules/auth/models/user.model';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit {

  public user: IUser = null;

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
  ) {
    if (authService.currentUserValue) {
      this.user = authService.currentUserValue.user;
    }
  }

  ngOnInit() {
  }

  logout() {
    this.authService.logout();
  }

}
