<svg xmlns="http://www.w3.org/2000/svg" id="lineChart" [attr.width]="svgWidth" [attr.height]="svgHeight">
  <g id="main">
    <g id="axis">
      <g id="axis-x">
        <ng-container *ngFor="let d of options.xAxis.ticks; let i = index">
          <g>
            <rect [attr.width]="xAxisTickLength"
                  [attr.height]="svgHeight"
                  [attr.x]="xAxisTickLength * i"></rect>
            <line [attr.x1]="xAxisTickLength * i + xAxisTickLength / 2"
                  [attr.y1]="0"
                  [attr.x2]="xAxisTickLength * i + xAxisTickLength / 2"
                  [attr.y2]="svgHeight"
                  stroke="#f9f9f9"
                  stroke-width="0.5"></line>
          </g>
        </ng-container>
      </g>
      <g id="axis-y">
        <g *ngFor="let d of options.yAxis.ticks; let i = index">
          <ng-container *ngIf="options.yAxis.lineShow">
            <line x1="0"
                  [attr.y1]="yAxisTickLength*i"
                  [attr.x2]="svgWidth"
                  [attr.y2]="yAxisTickLength*i"
                  stroke="#f9f9f9"
                  stroke-width="0.5"></line>
          </ng-container>
        </g>
      </g>
    </g>
    <g id="lines">
      <g class="fact-area">
        <path d="" [attr.fill]="fact.color" opacity="0.2"></path>
      </g>
      <g class="fact-line">
        <path d="" [attr.stroke]="fact.color" stroke-width="2" fill="none"></path>
      </g>
    </g>
    <g id="axis-labels">
      <g id="axis-x">
        <ng-container *ngFor="let d of options.xAxis.ticks; let i = index">
          <g>
            <rect [attr.width]="xAxisTickLength"
                  [attr.height]="svgHeight"
                  [attr.x]="xAxisTickLength * i"></rect>
            <ng-container *ngIf="options.xAxis.labelShow">
              <text [attr.x]="xAxisTickLength * i + xAxisTickLength / 2"
                    [attr.y]="svgHeight - 10"
                    fill="#797b86"
                    text-anchor="middle">{{d}}</text>
            </ng-container>
          </g>
        </ng-container>
      </g>
      <g id="axis-y">
        <g *ngFor="let d of options.yAxis.ticks; let i = index">
          <ng-container *ngIf="options.yAxis.labelShow">
            <g>
              <ng-container *ngIf="i != options.yAxis.ticks.length - 1">
                <text [attr.x]="svgWidth - 10"
                      [attr.y]="yAxisTickLength * i"
                      fill="#797b86"
                      text-anchor="end">{{getRounded(d)}}</text>
              </ng-container>
            </g>
          </ng-container>
        </g>
      </g>
    </g>
  </g>
</svg>
