import {Component, OnInit} from '@angular/core';
import {MessagingService} from '../../../services/messaging.service';
import {ScreenLoadingMessage} from '../../../../alerts';

@Component({
  selector: 'app-screen-loading-message',
  templateUrl: './screen-loading-message.component.html',
  styleUrls: ['./screen-loading-message.component.scss']
})
export class ScreenLoadingMessageComponent implements OnInit {

  public data: ScreenLoadingMessage;

  constructor(
    private messaging: MessagingService
  ) {
  }

  ngOnInit() {
    this.messaging.of(ScreenLoadingMessage).subscribe(response => {
      setTimeout(() => {
        this.data = response;
      });
    });
  }

  cancel() {
    this.messaging.publish(new ScreenLoadingMessage('', false));
  }

}
