import { Component, OnInit } from '@angular/core';
import {DataService} from '../../../data.service';
import {SponsorModel} from '../../../../../shared/models/mentor';
import exportFromJSON from 'export-from-json';

@Component({
  selector: 'app-sponsor',
  templateUrl: './sponsor.component.html',
  styleUrls: ['./sponsor.component.scss']
})
export class SponsorComponent implements OnInit {

  constructor(private dataService: DataService) { }

  data: SponsorModel[];
  version = 3;
  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.dataService.getSponsors(this.version).then(resp => {
      this.data = resp;
    });
  }

  export(){
    const data = this.data;
    const fileName = 'sponsors';
    const exportType =  exportFromJSON.types.xls;

    exportFromJSON({ data, fileName, exportType });
  }

}
