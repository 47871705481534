import {ITableRow} from "./landing.component";

export const FAQ = {
  ru: [{
    question: 'Как подать заявку для участия в конкурсе?',
    answer: 'Заявки на участие в конкурсе в качестве участника и ментора подаются в соответствующем разделе сайта «Заявки».',
    isOpen: true
  }, {
    question: 'Как выбрать ментора?',
    answer: 'Выбор ментора не объязателен, проводится по желанию команды. Список менторов размещен в разделе «Менторы». Для закрепления выбранного командой ментора отправляется на почту qazdatathon@gmail.com уведомление с одной конкретной кандидатурой из предложенного списка менторов.',
    isOpen: false
  }, {
    question: 'Когда и каким способом получим наборы данных?',
    answer: 'Датасеты будут направлены на Вашу корпоративную почту команды в течении дня 16 ноября т.г.',
    isOpen: false
  }, {
    question: 'Какие темы для исследования могу выбрать?',
    answer: 'Выбор темы свободный. Вместе с тем, проект должен решать определенные социально-экономические вызовы и в работах обязательно применение наборов синтетических данных, представленных Организаторами.',
    isOpen: false
  }, {
    question: 'Кто может голосовать?',
    answer: 'Голосование будет открытое и проходить в режиме online. Для каждой команды-участницы будет создана отдельная страничка с описанием команды и размещенным проектом, которой можно будет поделиться.',
    isOpen: false
  }, {
    question: 'Что подразумевает собой наборы синтетических данных?',
    answer: 'Наборы синтетические данных - это программно сгенерированные данные на основе фактических данных и учитывающие их паттерны и распределение, при этом обеспечивая конфиденциальность данных',
    isOpen: false
  }, {
    question: 'Где будет проходить финал?',
    answer: 'Финал будет проходить в online/offline формате на платформе Astana Hub. Объявление о месте проведения финала сообщается на сайте конкурса.',
    isOpen: false
  }],
  kk: [{
    question: 'Конкурсқа өтінімді қалай беруге болады?',
    answer: 'Конкурсқа ментор мен қатысушы ретінде өтінімдер «Өтінімдер» сайтының тиісті бөлімі арқылы беріледі.',
    isOpen: true
  }, {
    question: 'Менторды қалай таңдауға болады?',
    answer: 'Менторды таңдау міндетті емес, команданың қалауы бойынша жүзеге асырылады. Менторлардың тізімі «Менторлар» бөлімінде орналастырылған. Команда ұсынылған менторлар тізімінен бір нақты ментор  кандидатурасын таңдап, оны бекіту үшін qazdatathon@gmail.com поштасына хабарлама  жолдайды.',
    isOpen: false
  }, {
    question: 'Деректер жинағын қашан және қалай аламыз?',
    answer: 'Деректер жинағы ағымдағы жылдың 16 қарашасында бір күн ішінде команданың корпоративтік поштасына жіберіледі.',
    isOpen: false
  }, {
    question: 'Зерттеу үшін қандай тақырыптарды таңдауға болады?',
    answer: 'Тақырыпты таңдау еркін түрде. Сонымен қатар,   белгілі әлеуметтік-экономикалық сын-тегеуіріндерді шешуі тиіс және жұмыстарда ұйымдастырушылар ұсынған синтетикалық деректер жинағының біреуі қолданылуы керек.',
    isOpen: false
  }, {
    question: 'Кім дауыс бере алады?',
    answer: 'Дауыс беру ашық түрде болады және оnline режимде өтеді. Әрбір қатысушы команда үшін команданың сипаттамасы және орналастырылған жобасы бар жеке бет жасалады. Сондай-ақ, Сіз өзіңіздің командаңыздың бет сілтемесімен бөлісе аласыз.',
    isOpen: false
  }, {
    question: 'Синтетикалық деректер жинағы нені білдіреді?',
    answer: 'Синтетикалық деректер жинақтары – бұл деректердің құпиялылығын қамтамасыз ете отырып, олардың үлгілері мен таралуын ескере отырып, нақты деректерге негізделген бағдарламалық түрде жасалған деректер.',
    isOpen: false
  }, {
    question: 'Финал қай жерде өтеді?',
    answer: 'Финал Astana Hub платформасында online/offline форматында өтеді. Финалдың өтетін орны туралы хабарландыру конкурстың сайтында хабарланады.',
    isOpen: false
  }]
};

export interface ITable {
  ru: ITableRow[],
  kk: ITableRow[]
};

export const TABLE: ITable = {
  ru: [{
    number: 1,
    title: 'Объявление о проведении конкурса',
    date: '14 октября',
    items: [
      'размещение информации о конкурсе в социальных сетях для широкой аудитории;',
      'запуск сайта datathon.kz;',
      'рассылка приглашения для участия заинтересованным сторонам.'
    ]
  }, {
    number: 2,
    title: 'Запуск конкурса',
    date: '8 ноября',
    items: [
      'начало приема заявок;',
      'размещение образцов датасетов на сайте datathon.kz.'
    ]
  }, {
    number: 3,
    title: 'Подача заявок',
    date: 'до 13 ноября',
    items: [
      'заполнение заявок менторами заявки на сайте datathon.kz.'
    ]
  }, {
    number: null,
    title: null,
    date: 'с 8 по 15 ноября',
    items: [
      'заполнение командами онлайн-формы заявки на сайте datathon.kz'
    ]
  }, {
    number: 4,
    title: 'Подготовительная фаза',
    date: '14 ноября',
    items: [
      'размещение списка менторов на сайте datathon.kz.',
    ]
  }, {
    number: null,
    title: null,
    date: '14 ноября',
    items: [
      'workshop для менторов.',
    ]
  }, {
    number: null,
    title: null,
    date: 'с 15 по 16 ноября',
    items: [
      'выбор ментора (по желанию);',
      'отправка командами уведомления о выбранной кандидатуре ментора на почту qazdatathon@gmail.com.'
    ]
  }, {
    number: null,
    title: null,
    date: '16 ноября',
    items: [
      'workshop с целью погружения в детали (примеры кейсов) в онлайн формате.'
    ]
  }, {
    number: 5,
    title: 'Основная фаза',
    date: 'с 16 по 30 ноября',
    items: [
      'рассылка датасетов участникам;',
      'подготовка командами конкурсного проекта;',
      'направление командами работ (электронная версия проекта, а также презентации с краткой информацией о проекте соответствии с требованиями к конкурсной работе) на почту конкурса qazdatathon@gmail.com;',
      'размещение организаторами конкурса на сайте datathon.kz работ представленных участниками.',
    ]
  }, {
    number: null,
    title: null,
    date: 'с 1 по 2 декабря',
    items: [
      'проверка организаторами корректности формирования представленных работ и допуск к конкурсу согласно минимальным критериям оценки;',
      'объявление финалистов на сайте datathon.kz;',
      'направление финалистам по электронной почте информации по дате и месту проведения презентации конкурсных работ.'
    ]
  }, {
    number: 6,
    title: 'Финал',
    date: 'с 6 по 8 декабря',
    items: [
      'открытие голосования на сайте datathon.kz;',
      'подведение итогов голосования на сайте datathon.kz (30% от итогового результата).'
    ]
  }, {
    number: null,
    title: null,
    date: '9 декабря',
    items: [
      'презентация и защита проектных работ в режиме online/offline по выбору команды (регламент до 5-минут);',
      'сессия вопросов и ответов между жюри и участниками;',
      'подведение итогов оценки жюри (70% от итогового результата);',
      'подсчет результатов по итогам голосования и оценки жюри;',
      'торжественное награждение победителей.'
    ]
  }],
  kk: [{
    number: 1,
    title: 'Конкурсты туралы хабарландыру',
    date: '14 қазан',
    items: [
      'әлеуметтік желілерде кең аудиторияға конкурс туралы ақпаратты жариялау;',
      'datathon.kz сайтын іске қосу;',
      'қатысуға мүдделі жақтарға шақырулар жіберу.'
    ]
  }, {
    number: 2,
    title: 'Конкурстың басталуы',
    date: '8 қараша',
    items: [
      'өтінімдерді қабылдауды бастау;',
      'datathon.kz сайтында деректер жинақтарының үлгілерін орналастыру.'
    ]
  }, {
    number: 3,
    title: 'Өтінім беру',
    date: '13 қарашаға дейін',
    items: [
      'менторлардың datathon.kz сайтында өтінімді толтыруы.'
    ]
  }, {
    number: null,
    title: null,
    date: 'қарашаның 8-15 аралығында',
    items: [
      'командалардың datathon.kz сайтында өтінімнің онлайн-үлгісін толтыруы.'
    ]
  }, {
    number: 4,
    title: 'Дайындық кезеңі',
    date: '14 қараша',
    items: [
      'datathon.kz сайтында менторлардың тізімін орналастыру.',
    ]
  }, {
    number: null,
    title: null,
    date: '14 қараша',
    items: [
      'менторларға арналған workshop.',
    ]
  }, {
    number: null,
    title: null,
    date: 'қарашаның 15-16 аралығында',
    items: [
      'менторды таңдау (қалау бойынша);',
      'командалардың таңдаған ментор кандидатурасы туралы хабарламаны qazdatathon@gmail.com поштасына жіберуі.'
    ]
  }, {
    number: null,
    title: null,
    date: '16 қараша',
    items: [
      'онлайн форматта кейс мысалдарына терең түсіну мақсатындағы workshop.',
    ]
  }, {
    number: 5,
    title: 'Негізгі кезең',
    date: 'қарашаның 16-30 аралығында',
    items: [
      'қатысушыларға деректер жинағын жолдау;',
      'командалардың конкурс жобасын дайындауы;',
      'командалардың конкурстық жұмысты qazdatathon@gmail.com конкурс поштасына жолдауы (конкурс жұмыстарына қойылатын талаптарға сәйкес жобаның электронды нұсқасы, сондай-ақ жоба туралы қысқаша ақпараты бар презентация;',
      'конкурс ұйымдастырушыларының datathon.kz сайтына қатысушылардың ұсынған жұмыстарын орналастыруы.',
    ]
  }, {
    number: null,
    title: null,
    date: 'желтоқсанның 1-2 аралығында',
    items: [
      'ұйымдастырушылардың ұсынылған жұмыстарды қалыптастырудың дұрыстығын тексеруі және ең төменгі бағалау критерийлері бойынша конкурсқа рұқсат беруі;',
      'финалистерді datathon.kz сайтында жариялау;',
      'финалистерге конкурстық жұмыстарды презентация жасау күні мен орны туралы ақпаратты электрондық пошта арқылы жіберу.'
    ]
  }, {
    number: 6,
    title: 'Финал',
    date: 'желтоқсанның 6-8 аралығында',
    items: [
      'datathon.kz сайтында дауыс беруді ашу;',
      'datathon.kz сайтында дауыс берудің қорытындысын шығару (қорытынды нәтиженің 30%-ы).'
    ]
  }, {
    number: null,
    title: null,
    date: '9 желтоқсанда',
    items: [
      'команданың таңдауы бойынша online/offline режимде жоба жұмыстарын презентациялау және қорғау (регламент 5 минутқа дейін);',
      'қазылар алқасы мен қатысушылар арасындағы сұрақ-жауап сессиясы;',
      'қазылар алқасының қорытынды шығаруы (қорытынды нәтиженің 70%-ы);',
      'сайтта дауыс беру қорытындысы мен қазылар алқасының бағалауы нәтижесін есептеу;',
      'жеңімпаздарды салтанатты түрде марапаттау.'
    ]
  }]
};
